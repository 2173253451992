import React, { useState, useRef, useEffect } from 'react';
import { createPopper } from '@popperjs/core';

// Components
import { ClickOutsideHandler, Button, Checkbox } from 'app/common/components';

// Icons
import { CloseIcon } from 'app/common/icons';

// Style
import * as S from './CheckListDropdownStyled';

export interface FilterOption {
  label: React.ReactNode | string;
  count?: string;
}

interface CheckListDropdownProps {
  buttonLabel?: React.ReactElement | string;
  buttonStyle?: React.CSSProperties;
  modalTitle?: React.ReactElement | string;
  withCloseButton?: boolean;
  modalStyle?: React.CSSProperties;
  options: FilterOption[];
  modalButtonText: React.ReactElement | string;
}

export const CheckListDropdown = ({
  buttonLabel,
  buttonStyle,
  modalTitle,
  withCloseButton = false,
  modalStyle,
  options,
  modalButtonText,
}: CheckListDropdownProps) => {
  const dropdownButtonElement = useRef<HTMLButtonElement>();
  const modalElement = useRef<HTMLDivElement>();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (dropdownButtonElement.current && modalElement.current && show) {
      createPopper(dropdownButtonElement.current, modalElement.current, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      });
    }
  }, [show]);

  const toggleShow = () => setShow(state => !state);

  return (
    <>
      <S.DropdownContainer ref={dropdownButtonElement as any}>
        <Button
          displayAs="dropdown"
          className="white small"
          onClick={toggleShow}
          style={{ ...buttonStyle }}
        >
          {buttonLabel}
        </Button>
      </S.DropdownContainer>
      {show && (
        <ClickOutsideHandler onClickOutside={toggleShow} triggerElement={dropdownButtonElement}>
          <S.ModalContainer ref={modalElement as any} style={modalStyle}>
            <S.TitleModalContainer>
              {modalTitle && <span>{modalTitle}</span>}
              {withCloseButton && (
                <S.CloseContainer onClick={() => setShow(false)}>
                  <CloseIcon height={16} width={16} />
                </S.CloseContainer>
              )}
            </S.TitleModalContainer>
            <S.BlueText>Clear filter</S.BlueText>
            <S.OptionsContainer>
              {options.map(op => (
                <S.OptionContainer>
                  <Checkbox label={op.label} style={{ display: 'flex' }} />
                  {op.count && <span>{op.count}</span>}
                </S.OptionContainer>
              ))}
            </S.OptionsContainer>
            <Button className="primary" style={{ width: '304px' }}>
              {modalButtonText}
            </Button>
          </S.ModalContainer>
        </ClickOutsideHandler>
      )}
    </>
  );
};

export default CheckListDropdown;
