import gql from 'graphql-tag';

export const FormFragment = gql`
  fragment FormBaseFragment on Form {
    id
    type
    status
    note
    code
    content {
      input
      question
      key
      value
    }
    isActive
    measure {
      noteCount
      attachmentCount
    }
    author {
      id
      firstName
      lastName
    }
    entity {
      id
      name
    }
    files {
      link
    }
    createdAt
    deactivatedAt
    assignedTo {
      id
      firstName
      lastName
    }
  }
`;
