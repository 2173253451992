import { IAnswer, IQuestion } from './diligence';
import { Fso as IFso } from 'app/core-tools/due-diligence/vdr/utils/types';

export enum AppContext {
  VDR = 'vdr',
  DILIGENCE = 'diligence',
  QNA = 'qna',
}

export enum DealSide {
  BUY = 'buy',
  SELL = 'sell',
}

export enum DealMode {
  LIGHT = 'light',
  REGULAR = 'regular',
}

export enum Role {
  USER = 'user',
  ADMIN = 'admin',
  REMOVE_USER = 'remove',
  MMBO = 'mmbo',
}

export enum PlatformRole {
  USER = 'user',
  GOD = 'god',
  MMBO = 'mmbo',
  MARKETPLACE_ONLY_USER = 'marketplace_only_user',
  DEAL_FLOW_LAUNCH = 'deal_flow_launch',
}

export enum EGroupType {
  DEAL = 'deal',
  BUY = 'buy',
  SELL = 'sell',
  ORGANIZATION = 'organization',
  CUSTOM = 'custom',
}

export enum EGroupRightLabel {
  ACCESS_LEVEL = 'Access Level',
  MEMBERS = 'Members',
}

export enum Sort {
  A_GOES_FIRST = -1,
  B_GOES_FIRST = 1,
  EQUAL = 0,
}

export type IOrganization = {
  id: string;
  name: string;
};

export type IUser = {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  role: Role;
  side: DealSide;
  organization: IOrganization;
};

export type IGroup = {
  id: string;
  owner: string;
  name: string;
  type: EGroupType;
  createdAt: string;
  updatedAt: string;
  users: string[];
  relatedGroups: {
    parents: string[];
    children: string[];
  };
};

export enum BasicEntities {
  FSO = 'fso',
  QUESTION = 'question',
}

export enum LinkEntityTypes {
  THREAD = 'thread',
  FOLLOWUP = 'followup',
  QUESTION = 'question',
  ANSWER = 'answer',
  FSO = 'fso',
}

export type Link = {
  id: string;
  originId: string;
  targetId: string;
  createdAt: string;
};

export type LinkedEntity = {
  id: string;
  type: LinkEntityTypes;
  entity: IAnswer | IQuestion | IFso;
  link: Link;
};

export type IPermissionEntity = {
  id: string;
  context: AppContext;
  name: string;
  displayName: string;
  description: string;
  restrictions: {
    sides: DealSide;
  };
};

export type IPermissionByEntity = {
  entityId: string;
  permissions: {
    id: string;
    entityId: string;
    ownerId: string;
    permissionEntityId: string;
    active: boolean;
  }[];
};

export type IOption = {
  label: string;
  value: string;
};

export enum ERoutePatterns {
  ROOT = '/',
  DOCUMENT_BANK = '/workflow/document-bank',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SIGNUP = '/sign-up',
  RECOVERY = '/password-recovery',
  VDR = '/vdr',
  VDR_DIRECTORIES = '/vdr/directories',
  NOTIFICATIONS = '/notifications',
  HOME = '/home',
  FAQ = '/faq',
  DOWNLOAD_FSO = 'v1/fso/download/',
  DOWNLOAD_INDEX = 'v1/fso/index/',
  SIGNATURE_COMPLETED = '/signature-completed',
  COMPLIANCE_TRACKER = '/workflow/compliance-tracker',
  REGULATORY_PROFILE_TRACKER = '/workflow/regulatory-profile-tracker',
  CONNECT = '/connect',
  MARKETPLACE = '/connect/marketplace',
  MARKETPLACE_DIRECTORY = '/connect/directory',
  MARKETPLACE_V2_DIRECTORY = '/fns-connect/directory',
  MARKETPLACE_FEE_GUIDE = '/connect/fee-guide',
  MARKETPLACE_FUNDS = '/connect/funds',
  MARKETPLACE_CONVERSATIONS = '/connect/conversations',
  ONBOARDING = '/onboarding',
  ATTESTATION = '/attestation',
  DEALS_ATTESTATION = '/attestation/deals',
  OBA_ATTESTATION = '/attestation/oba',
  PST_ATTESTATION = '/attestation/pst',
  OBACCT_ATTESTATION = '/attestation/obaccounts',
  RESIDENTIAL_ADDRESS_ATTESTATION = '/attestation/residential-address',
  BANK_ACCOUNT_ATTESTATION = '/attestation/bank-account',
  REVIEW_ATTESTATION = '/attestation/review',
  MY_ACCOUNT = '/my-account',
  REGULATORY_PROFILE = 'reg-profile',
  ACCOUNT_SETTINGS = 'account-set',
  PAYMENTS = 'payments',
  MONTHLY_MEMBERSHIP = 'monthly-membership',
  ANCILLARY_FEES = 'fee-schedule',
  RP_BASIC_INFO = 'basic-info',
  RP_REGISTERED_STATES = 'registered-states',
  RP_BANK_ACCOUNT = 'bank-account',
  RP_ATTESTATIONS = 'attestations',
  AS_USER_SETTINGS = 'user-settings',
  MY_ACCOUNT_SETTINGS = '/my-account/account-set/user-settings',
  AS_PRIVACY_SECURITY = 'privacy-security',
  DEAL_REGULATION = '/deal-regulation',
  HELP_CENTER = 'https://faq.finalis.com/knowledge',
  REFERRAL_PROGRAM = 'https://form.finalis.com/fs/client-referral-program',
  DEAL_REFERRAL = 'https://form.finalis.com/fs/deal-referral',
  INSIGHTS = 'https://go.finalis.com/finalis-insights',
  FINALIS_URL = 'https://www.finalis.com/',
  PROXY_LOGIN = '/proxy-login',
  MY_DEALS = '/my-deals',
  DEALS_CREATE_DEAL = '/fns-workflow/deals/create-deal',
  DEALS = '/fns-workflow/deals',
}

export enum EButtonMessages {
  CANCEL_ACTION = 'Cancel',
  CONFIRM_ACTION = 'Ok',
  SAVE_ACTION = 'Save changes',
  CREATE_FILEROOM_ACTION = 'Create',
}

export type FsoType = {
  id: string;
  name: string;
  createdAt: string;
};

export enum ArrowIconDirections {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  DOWN_RIGHT = 'downRight',
}

export enum LogsLevels {
  ANALYTICS = 'analytics',
  INFO = 'info',
}

export enum ESortingOrders {
  ASC = 'asc',
  DESC = 'desc',
}

export enum DnDMessages {
  DEFAULT = 'Drop here to attach files.',
}

export enum EmptyFolderMessages {
  SELL_SIDE = 'Drag and drop from your computer, or click the upload button.',
  BUY_SIDE = 'This folder is empty, please come back later.',
}

export const PUBLIC_ROUTES = [
  ERoutePatterns.LOGIN,
  ERoutePatterns.SIGNUP,
  ERoutePatterns.RECOVERY,
  ERoutePatterns.SIGNATURE_COMPLETED,
  ERoutePatterns.LOGOUT,
  ERoutePatterns.PROXY_LOGIN,
];
