import styled from 'styled-components';
import { History } from 'history';

const MainContainer = styled.section`
  display: flex;
`;

const ContextItemContainer = styled.div<{ light?: boolean }>`
  .is-disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 3px;
  background-color: ${({ light }) => (light ? '#E3E3E3' : '#D6D6D6')};
  color: ${({ light }) => (light ? '#576E87' : '#545658')};

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Section = styled.section<{ isActive?: boolean; noFocus?: boolean }>`
  /* TODO: Change this color def to theme gray color once official
  colors are updated.*/
  background-color: ${({ isActive }) => (isActive ? '#F2F7FD' : 'transparent')};
  padding: 14px 16px;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:first-child {
    padding: 14px 16px;
  }

  &:last-child {
    padding: 14px 16px;
  }

  ${({ noFocus }) => {
    if (noFocus) return ``;

    return `
      &:hover {
        /* TODO: Change this color def to theme gray color once official
        colors are updated.*/
        background-color: #f2f7fd;
      }
    `;
  }}
`;

const Header = styled.h1<{ to?: History.LocationDescriptor<any> }>`
  text-transform: none;
  font-size: ${({ theme }) => theme.fontSizes.regularLarge};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: #596573;
  display: block;
  text-decoration: none;
`;

const Anchor = styled.a<{ disabled?: boolean }>`
  display: flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  margin: 0 0 15px 15px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.gray.dark : theme.palette.gray.darker};
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
`;

export { MainContainer, ContextItemContainer, Section, Header, Anchor };
