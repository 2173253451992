import bytes from 'bytes';

export type FileStateSetter<T = any> = (state: T) => void;
export type FileUploaderFn<T = void> = (instance: ExtendedFile) => Promise<T>;

export enum FileStatus {
  Ready = 'Ready',
  Failed = 'Failed',
  Uploading = 'Uploading',
  Completed = 'Completed',
}

export class ExtendedFile {
  id: string;
  file: File;
  progress: number;
  status: FileStatus;
  stateSetter: FileStateSetter<ExtendedFile>;
  uploaderFunction: FileUploaderFn | undefined;

  constructor(file: File, setState: FileStateSetter<ExtendedFile>, uploaderFn?: FileUploaderFn) {
    this.file = file;
    this.id = Buffer.from(file.name + Math.random()).toString('base64');
    this.progress = 100;
    this.status = FileStatus.Ready;
    this.uploaderFunction = uploaderFn;
    this.stateSetter = setState;
    if (this.stateSetter) {
      this.stateSetter(this);
    }
  }

  get filename(): string {
    return this.file.name;
  }

  get fileSize(): string {
    return bytes(this.file.size || 0, {
      decimalPlaces: 0,
      unitSeparator: ' ',
      thousandsSeparator: ',',
    });
  }

  get extension(): string {
    const splittedName = this.file.name.split('.');
    const extension = splittedName[splittedName.length - 1];
    return extension.toLocaleUpperCase();
  }

  async upload() {
    if (this.uploaderFunction) {
      await this.uploaderFunction(this);
    }
  }
}
