import React, { useContext, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { ContextMenu, SuitecaseIcon, CaretIcon, EDirections } from '@dealsyte/poki';
import get from 'lodash/get';
import EventService from 'utils/socket/EventService';
import { UserContext } from 'app/users/context/UserContext';
import { ERoutePatterns } from 'app/core-tools/due-diligence/types/types';
import { IsUserSeller } from 'app/utils/helpers/helpers';
import { ContextItemContainer } from '../../../../StyledGlobalSidebar';

import { MainContainer, Header } from './StyledDealContentSidebarHeader';
import { EFeatureFlag } from 'app/FeatureFlag';
import { useFeatureFlags } from 'app/utils/hooks/hooks';

/**
 * Renders the deal content sidebar header as a trigger modal to open deal settings.
 * @param setShowSettingsModal - Dispatch function that toggles SettingsModal component display.
 */
function DealContentSidebarHeader({
  setShowSettingsModal,
}: {
  setShowSettingsModal: Dispatch<SetStateAction<boolean>>;
}) {
  const user = useContext(UserContext);
  const isActiveDeal = !!user.activeDeal;
  const isCreateDealFFon = useFeatureFlags(EFeatureFlag.CN_DEAL_CREATE_AND_EDIT);

  const location = useLocation();

  /**
   * Gets the header text based on the current location.
   */
  function getHeaderText(): string {
    switch (location.pathname) {
      case ERoutePatterns.HOME:
        return get(user, 'activeDeal.name', 'Home');

      case ERoutePatterns.COMPLIANCE_TRACKER:
        return get(user, 'activeDeal.name', 'Compliance');

      default:
        return get(user, 'activeDeal.name', '...');
    }
  }

  const sellerUser = IsUserSeller(get(user, 'side', ''));

  const renderHeader = () => {
    let cursor = 'default';

    if (sellerUser) {
      cursor = isActiveDeal ? 'pointer' : 'none';
    }

    return (
      <MainContainer
        onClick={
          !isCreateDealFFon && sellerUser
            ? event => {
                if (!isActiveDeal) {
                  event.stopPropagation();
                }
              }
            : undefined
        }
        style={{ cursor: isCreateDealFFon ? 'default' : 'pointer' }}
      >
        <ContextItemContainer
          style={{
            margin: '0 12px 0 0',
            backgroundColor: '#fff',
            color: '#959FAC',
            cursor: isCreateDealFFon ? 'default' : sellerUser ? 'pointer' : 'default',
          }}
        >
          <SuitecaseIcon />
        </ContextItemContainer>{' '}
        <Header>{getHeaderText()}</Header>
        {!isCreateDealFFon &&
          (isActiveDeal && sellerUser && <CaretIcon direction={EDirections.down} />)}
      </MainContainer>
    );
  };

  if (!sellerUser) {
    return renderHeader();
  }

  return isCreateDealFFon ? (
    renderHeader()
  ) : (
    <ContextMenu
      style={{ width: '170px' }}
      actions={[
        {
          label: 'Deal configuration',
          onClick() {
            setShowSettingsModal(true);
            EventService.triggerEvent('MODAL_SHOW', ['deal_settings']);
          },
        },
      ]}
      offset={4}
      paddingLeft={16}
      align="left"
      triggerButton={renderHeader()}
    />
  );
}

export default DealContentSidebarHeader;
